<template>
  <div>
    <div class="profileDiv">
      <div style="border: 1px solid #909399; width: 200px"></div>
      <div
        style="border: 3px solid #303133; width: 300px; margin-top: 3px"
      ></div>
      <div>
        <div class="title">产品中心</div>
        <div class="titleEn">PRODUCT CENTER</div>
        <div class="info">
          <el-row :gutter="10">
            <el-col :span="12">
              <div style="text-align: center;">
                <el-image style="height: 30vw" :src="productImg"></el-image>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <div class="info_">
                  <span class="title_">药品介绍：</span><span>抗衰老口服液</span>
                </div>
                <div class="info_">
                  <span class="title_">批准文号：</span
                  ><span>国药准字B20021016</span>
                </div>
                <div class="info_">
                  <span class="title_">处方成分：</span
                  ><span
                    >本品主要成分为生地黄、红参、麦冬、天冬、地骨皮、茯苓。</span
                  >
                </div>
                <div class="info_">
                  <span class="title_">功能与主治：</span
                  ><span
                    >抗衰老口服液具有益气养阴、宁心安神的功效。它专为中、老年体弱者设计，适用于因气阴两虚所导致的一系列症状，如神疲乏力、心悸气短、少气懒言、头晕目眩、潮热盗汗、耳鸣健忘以及烦躁失眠等。</span
                  >
                </div>
                <div class="info_">
                  <span class="title_">不良反应：</span
                  ><span
                    >在服用抗衰老口服液期间，肝火旺盛的患者可能会出现舌燥的症状。如出现此类症状，请咨询医生或药师的建议。</span
                  >
                </div>
                <div class="info_">
                  <span class="title_">禁忌：</span
                  ><span
                    >抗衰老口服液不宜与含有藜芦、五灵脂的中成药同时使用，以免发生药物相互作用。</span
                  >
                </div>
                <div class="info_"><span class="title_">注意事项</span></div>
                <div class="info_"><span>1.忌辛辣、生冷、油腻食物，以免影响药效。</span></div>
                <div class="info_"><span>2.感冒发热病人不宜服用，以免加重病情。</span></div>
                <div class="info_"><span>3.本品宜饭前服用，以更好地发挥药效。</span></div>
                <div class="info_">
                  <span
                    >4.高血压、心脏病、肝病、糖尿病、肾病等慢性病患者应在医师指导下服用，以确保用药安全。</span
                  >
                </div>
                <div class="info_">
                  <span
                    >5.服药2周后症状无缓解，应及时去医院就诊，以免延误病情。</span
                  >
                </div>
                <div class="info_">
                  <span>6.孕妇应在医师指导下服用，以确保母婴安全。</span>
                </div>
                <div class="info_">
                  <span
                    >7.对本品过敏者禁用，过敏体质者慎用，以免发生过敏反应。</span
                  >
                </div>
                <div class="info_">
                  <span
                    >8.如发现本品性状发生改变，请立即停止服用，并咨询医生或药师的建议。</span
                  >
                  <span
                    >9.请将本药品放在儿童不能接触的地方，以确保用药安全。</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="title">相关证书</div>
        <div class="titleEn">RELEVANT CERTIFICATES</div>
        <div class="info">
          <el-row :gutter="10">
            <el-col :span="12">
              <div style="text-align: center;">
                <el-image style="height: 30vw" :src="z1"></el-image>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="text-align: center;">
                <el-image style="height: 30vw" :src="z2"></el-image>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div
        style="
          border: 3px solid #303133;
          width: 300px;
          margin-left: 980px;
          margin-top: 30px;
        "
      ></div>
      <div
        style="
          border: 1px solid #909399;
          width: 200px;
          margin-top: 3px;
          margin-left: 1080px;
        "
      ></div>
    </div>
  </div>
</template>

<script>
import * as webApi from "../../../api/webApi";

export default {
  data() {
    return {
      list: [],
      goodsList: [],
      activeName: "",
      productImg:
        "https://staticres.jiuyinqing.com/cdr/5f6f1c31e687c8efcdbfc6db0c8b6fd6.jpg",
        z1: require("@/assets/image/z1.png"),
        z2: require("@/assets/image/z2.png"),
    };
  },
  methods: {
    getProductGenreList() {
      var that = this;
      webApi.getProductGenreList().then((res) => {
        that.list = res.data.data;
        that.getGoodsInfo(that.list[0].id, 0);
        that.activeName = "" + that.list[0].id;
      });
    },
    getGoodsInfo(productGenreId, isRecommend) {
      var that = this;
      var data = { productGenreId: productGenreId, isRecommend: isRecommend };
      webApi.selectGoodsInfo(data).then((res) => {
        that.goodsList = res.data.data;
      });
    },
    handleClick() {
      this.getGoodsInfo(this.activeName, 0);
    },
  },
  created() {
    this.getProductGenreList();
  },
};
</script>

<style scoped>
.profileDiv {
  width: 1280px;
  margin: 0 auto;
  margin-top: 40px;
}
.title,
.titleEn {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.title {
  width: 300px;
  padding-top: 30px;
  line-height: 60px;
  margin: 0 auto;
  border-bottom: 4px solid #f56c6c;
}
.titleEn {
  line-height: 60px;
}
.info {
  margin-top: 40px;
}
.newDiv {
  margin-bottom: 40px;
  border: 1px solid #303030;
  width: 100%;
  height: 20vw;
}
.newInfo {
  font-size: 16px;
  width: 90%;
  margin-left: 5%;
  line-height: 25px;
  font-weight: bold;
  margin-top: 10px;
  color: #000;
}
.title_ {
  font-weight: bolder;
}
.info_{
  font-size:14px;
  line-height:25px;
  margin-bottom:5px;

}
</style>
